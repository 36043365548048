export const background = require('./background.jpeg');
export const balloons = require('./balloons.png');
export const invitation_1 = require('./invitation_1.jpeg');
export const invitation_2 = require('./invitation_2.jpeg');
export const invitation_3 = require('./invitation_3.jpeg');
export const restaurant_1 = require('./restaurant_1.png');
export const restaurant_2 = require('./restaurant_2.png');
export const restaurant_3 = require('./restaurant_3.png');
export const webex = require('./webex.png');
export const five = require('./five.png');
export const zero = require('./zero.png');










